<template>
  <v-main>
    <nav-bar/>
    <template slot:top>
      <v-subheader>
        <h1>Dossiers</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{'items-per-page-options':[50]}"
      dense
    >
      <template v-slot:item.tenant="{ item }">
      <v-btn
        small
        class="info"
        @click="editRenter(item.tenantUid)"
      >
        {{ item.tenant }}
      </v-btn>
    </template>

     <template v-slot:item.certifications="{ item }">
        <span 
        :class="item.certifications > 0 
        ? 'red--text font-weight-black' 
        : ''
        " 
        class="text-no-wrap">

          {{ item.certifications }}

        </span>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        target="_blank"
        @click="editItem(item.uid)"
      >
        mdi-pencil
      </v-icon>
    </template>
    </v-data-table>
  </v-main>
</template>

<script>
  import axios from 'axios'
  import NavBar from '../components/navBar.vue'

  export default {
    components:{
      NavBar,
    },
    data: () => ({
      headers: [
        { text: 'Id', value: 'id'},
        { text: 'Locataire', value: 'tenant' },
        { text: 'Type', value: 'situation' },
        { text: 'Nom', value: 'tenantLastname' },
        { text: 'Prénom', value: 'tenantFirstname' },
        { text: 'Certifications', value: 'certifications' },
        { text: 'Statut', value: 'status' },
        { text: 'Date de création', value: 'creationDate' },
        { text: "Date d'activation", value: 'activationDate' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      searchFields:[
        {text: 'Dans toutes les colonnes', value: 'id,tenant,situation,tenantFirstname,tenantLastname,status,creationDate,activationDate'},
        {text: 'Id', value: 'id'},
        {text: 'Locataire', value: 'tenant'},
        {text: 'Type', value: 'situation'},
        {text: 'Nom', value: 'tenantLastname'},
        {text: 'Prénom', value: 'tenantFirstname'},
        {text: 'Statut', value: 'status'},
        {text: 'Date de création', value: 'creationDate' },
        {text: "Date d'activation", value: 'activationDate' },
      ],
      searchField: 'id,tenant,situation,tenantFirstname,tenantLastname,status,creationDate,activationDate',
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ['id'],
        sortDesc: [true]
      },
      query: '',
    }),

    watch: {
      options: {
        handler () {
          this.getItems()
        },
        deep: true
      },
    },

    methods: {
      getItems () {
        axios.get(process.env.VUE_APP_APIURL + '/rentalFiles', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort: this.options.sortBy[0] + ' ' + (this.options.sortDesc[0] ? 'DESC' : 'ASC'),
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField,
            expand: 'situation'
          }
        })
        .then(response => {
          this.items = response.data.map(item => Object.assign(item, 
            {status: { 1: "1 Créé 🕒", 2: "2 Terminé ✅"}[item.status]},
            {creationDate: new Date(item.creationDate).toLocaleString()},
            {activationDate: item.activationDate != null ? new Date(item.activationDate).toLocaleString() : ''},
            {situation: item.situation != null ? `${item.situation.id} ${item.situation.label}` : ''},
            {tenantPhone: item.tenantPhone ? item.tenantPhone : ""}
          ))
        })
        axios.get(process.env.VUE_APP_APIURL + '/rentalFiles', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            count: 1,
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField
          }
        })
        .then(response => {
          this.itemsLength = eval(response.data[0].count)
        })
      },

      search () {
        this.options.page = 1
        this.getItems()
      },

      editRenter (uid) {
        open(this.$router.resolve({name: 'user', params: {userUid: uid}}).href, '_blank');
      },

      editItem (uid) {
        open(this.$router.resolve({name: 'rentalFile', params: {rentalFileUid: uid}}).href, '_blank');
      },
    }
  }
</script>

<style>

</style>
