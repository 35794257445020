var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('nav-bar'),[_c('v-subheader',[_c('h1',[_vm._v("Dossiers")])]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"pr-2 flex-grow-1",attrs:{"label":"Mot clé"},on:{"input":_vm.search},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-select',{staticClass:"pl-2 flex-grow-0",attrs:{"items":_vm.searchFields,"dense":"","solo":""},on:{"change":_vm.search},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1)],_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsLength,"options":_vm.options,"must-sort":true,"footer-props":{'items-per-page-options':[50]},"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tenant",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"info",attrs:{"small":""},on:{"click":function($event){return _vm.editRenter(item.tenantUid)}}},[_vm._v(" "+_vm._s(item.tenant)+" ")])]}},{key:"item.certifications",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",class:item.certifications > 0 
      ? 'red--text font-weight-black' 
      : ''},[_vm._v(" "+_vm._s(item.certifications)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","target":"_blank"},on:{"click":function($event){return _vm.editItem(item.uid)}}},[_vm._v(" mdi-pencil ")])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }